import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import { ProductInformation as SourceProductInformation } from 'SourceComponent/ProductInformation/ProductInformation.component';

import './ProductInformation.override.style';

/** @namespace Pwa/Component/ProductInformation/Component/ProductInformation */
export class ProductInformation extends SourceProductInformation {
    renderShortDescription() {
        const {
            product: {
                short_description: { html },
            },
        } = this.props;

        return (
            <div mix={{ block: 'ProductInformation', elem: 'ShortDescription' }}>
                <Html content={html} />
            </div>
        );
    }

    renderContent() {
        const { areDetailsLoaded } = this.props;
        const heading = areDetailsLoaded ? __('About Product') : '';

        return (
            <div heading={heading} mix={{ block: 'ProductInformation', elem: 'Content' }}>
                <h2 block="ProductInformation" elem="Header">
                    {heading}
                </h2>
                {this.renderShortDescription()}
            </div>
        );
    }

    render() {
        const {
            areDetailsLoaded,
            product: {
                description: { html } = {},
                short_description: { html: short_description_html },
            },
        } = this.props;

        if (
            this.isHTMLWhiteSpaceOrUndefined(html) &&
            this.isHTMLWhiteSpaceOrUndefined(short_description_html) &&
            areDetailsLoaded
        ) {
            return null;
        }

        return (
            <ContentWrapper
                label="Product information"
                mix={{ block: 'ProductInformation' }}
                wrapperMix={{ block: 'ProductInformation', elem: 'Wrapper' }}
            >
                {this.renderContent()}
            </ContentWrapper>
        );
    }
}

export default ProductInformation;
